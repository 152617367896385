import { DEFAULT_PATHS } from 'config.js';
import { lazy } from 'react';
import Login from 'views/default/Login';

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const kanban = {
  list: lazy(() =>
    import('crm-portal/view/kanban/index')),
  deal: lazy(() =>
    import('crm-portal/view/innerDeal/index')),
};
const boxes = {
  list: lazy(() =>
    import('views/boxes/Boxes')),
  edit: lazy(() =>
    import('views/boxes/EditBox')),
  create: lazy(() =>
    import('views/boxes/CreateBox')),
};
const readymade = {
  list: lazy(() =>
    import('views/ready-made-gifts/ReadyMadeGifts')),
  edit: lazy(() =>
    import('views/ready-made-gifts/EditReadyMadeGifts')),
  create: lazy(() =>
    import('views/ready-made-gifts/CreateReadyMadeGifts')),
};
const occasions = {
  list: lazy(() =>
    import('views/occasions/Occasions')),
  edit: lazy(() =>
    import('views/occasions/EditOccasions')),
  create: lazy(() =>
    import('views/occasions/CreateOccasions')),
};
const products = {
  list: lazy(() =>
    import('views/products/Products')),
  edit: lazy(() =>
    import('views/products/EditProducts')),
  create: lazy(() =>
    import('views/products/CreateProducts')),
};
const categories = {
  list: lazy(() =>
    import('views/categories/Categories')),
  edit: lazy(() =>
    import('views/categories/EditCategory')),
  create: lazy(() =>
    import('views/categories/CreateCategory')),
};
const cards = {
  list: lazy(() =>
    import('views/cards/Cards')),
  edit: lazy(() =>
    import('views/cards/EditCards')),
  create: lazy(() =>
    import('views/cards/CreateCards')),
};
const vendors = {
  list: lazy(() =>
    import('views/vendors/Vendors')),
  edit: lazy(() =>
    import('views/vendors/EditVendor')),
  create: lazy(() =>
    import('views/vendors/CreateVendor')),
};
const users = {
  list: lazy(() =>
    import('views/users/Users')),
  edit: lazy(() =>
    import('views/users/EditUsers')),
  create: lazy(() =>
    import('views/users/CreateUsers')),
};
const brands = {
  list: lazy(() =>
    import('views/brands/Brands')),
  edit: lazy(() =>
    import('views/brands/EditBrand')),
  create: lazy(() =>
    import('views/brands/CreateBrand')),
};
const carts = {
  list: lazy(() =>
    import('views/carts/Carts')),
  edit: lazy(() =>
    import('views/carts/EditCart')),
  create: lazy(() =>
    import('views/carts/CreateCart')),
};
const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: `${appRoot}/login`,
      exact: true,
      redirect: true,
      label: 'Logout',
      icon: 'logout',

    },
    {
      path: `${appRoot}/manage-leads`,
      label: 'Manage leads',
      icon: 'clipboard',
      component: kanban.list,
    },
    {
      path: `${appRoot}/lead/:lead/deal/:deal`,
      component: kanban.deal,
    },
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/login`,
    },
    {
      path: `${appRoot}/login`,
      component: Login,
    },
    {
      path: `${appRoot}/boxes`,
      label: 'Boxes',
      icon: 'box',
      subs: [
        { path: `/list`, label: 'List', component: boxes.list },
        { path: `/create`, label: 'Create', component: boxes.create },
        { path: `/edit/:id`, component: boxes.edit }
      ],
    },
    {
      path: `${appRoot}/categories`,
      label: 'Categories',
      icon: 'shop',
      subs: [
        { path: `/list`, label: 'List', component: categories.list },
        { path: `/create`, label: 'Create', component: categories.create },
        { path: `/edit/:id`, component: categories.edit }
      ],
    },
    {
      path: `${appRoot}/readymade`,
      label: 'Ready Made Gifts',
      icon: 'gift',
      subs: [
        { path: `/list`, label: 'List', component: readymade.list },
        { path: `/create`, label: 'Create', component: readymade.create },
        { path: `/edit/:id`, component: readymade.edit }
      ],
    },
    {
      path: `${appRoot}/products`,
      label: 'Products',
      icon: 'dollar',
      subs: [
        { path: `/list`, label: 'List', component: products.list },
        { path: `/create`, label: 'Create', component: products.create },
        { path: `/edit/:id`, component: products.edit }
      ],
    },
    {
      path: `${appRoot}/carts`,
      label: 'Orders',
      icon: 'cart',
      subs: [
        { path: `/list`, label: 'List', component: carts.list },
        { path: `/edit/:id`, component: carts.edit }
      ],
    },
    {
      path: `${appRoot}/occasions`,
      label: 'Occasions',
      icon: 'calendar',
      subs: [
        { path: `/list`, label: 'List', component: occasions.list },
        { path: `/create`, label: 'Create', component: occasions.create },
        { path: `/edit/:id`, component: occasions.edit }
      ],
    },
    {
      path: `${appRoot}/cards`,
      label: 'Cards',
      icon: 'credit-card',
      subs: [
        { path: `/list`, label: 'List', component: cards.list },
        { path: `/create`, label: 'Create', component: cards.create },
        { path: `/edit/:id`, component: cards.edit }
      ],
    },
    {
      path: `${appRoot}/vendors`,
      label: 'Vendors',
      icon: 'suitcase',
      subs: [
        { path: `/list`, label: 'List', component: vendors.list },
        { path: `/create`, label: 'Create', component: vendors.create },
        { path: `/edit/:id`, component: vendors.edit }
      ],
    },
    {
      path: `${appRoot}/users`,
      label: 'Users',
      icon: 'user',
      subs: [
        { path: `/list`, label: 'List', component: users.list },
        { path: `/create`, label: 'Create', component: users.create },
        { path: `/edit/:id`, component: users.edit }
      ],
    },
    {
      path: `${appRoot}/brands`,
      label: 'Brands',
      icon: 'tag',
      subs: [
        { path: `/list`, label: 'List', component: brands.list },
        { path: `/create`, label: 'Create', component: brands.create },
        { path: `/edit/:id`, component: brands.edit }
      ],
    },
  ],
  sidebarItems: [],
};
export default routesAndMenuItems;
