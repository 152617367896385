import {
    KANBAN_LIST_SUCCESS,
    KANBAN_LIST_FAIL
} from "../constant/kanBanConstants";

export const kanBanReducer = (
    state = { loading: true, kanban: false },
    action
) => {
    switch (action.type) {
        case KANBAN_LIST_SUCCESS:
            return { loading: false, kanban: action.payload };
        case KANBAN_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};
